<ng-container *ngIf="productLine == allProductLine.panametrics">
  <div class="quickLinks-products-header">
    <div class="header-green-border"></div>
    <h1>Quick access</h1>
    <div class="container quick-link-container">
      <a
        *ngFor="let item of menuItems"
        [href]="item.link"
        class="container-item"
      >
        <div class="quickLinkBox">
          <ng-container *ngIf="item.name === 'Create RM'; else defaultIcon">
            <span class="material-icons playlist-add-icon">playlist_add</span>
          </ng-container>
          <ng-template #defaultIcon>
            <img [src]="item.iconImg" class="playlist-add-icon" />
          </ng-template>
          {{ item.name }}
        </div>
      </a>
    </div>
  </div>
</ng-container>
