import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService, WindowRef } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CheckoutPaymentModel,
  SnapPayPaymentInfo,
} from '../../../../checkout/buy-checkout/buy-checkout.model';
import { BuyCheckoutService } from '../../../../checkout/buy-checkout/service/buy-checkout.service';
import { GuestBuyCheckoutService } from '../../../../checkout/guest-checkout/services/guest-buy-checkout.service';
import { CustomerAccountService } from '../../../../../core/customer-account/customer-account.service';

@Component({
  selector: 'app-waygate-payment',
  templateUrl: './waygate-payment.component.html',
  styleUrls: ['./waygate-payment.component.scss'],
})
export class WaygatePaymentComponent {
  @Output() setPaymentData: EventEmitter<any> = new EventEmitter();
  @Output() sendPayInfoData: EventEmitter<any> = new EventEmitter();
  @Output() isPaymentValid: EventEmitter<any> = new EventEmitter();
  @Input() paymentTerm;
  @Input() completeOrder;
  @Input() cart$: Observable<any>;
  @Input() isEndUserType: boolean;
  $subscription: Subscription;
  checkoutPaymentModel: CheckoutPaymentModel;
  checkoutMessages: any;
  productLine: string;

  constructor(
    private chkService: GuestBuyCheckoutService,
    private translate: TranslationService,
    private activeCartFacade: ActiveCartFacade,
    protected winRef: WindowRef,
    private buyUserChkService: BuyCheckoutService,
    private custAccService: CustomerAccountService
  ) {
    this.checkoutPaymentModel = new CheckoutPaymentModel();
  }
  PoNumber: any = '';
  endPoNumber: string;
  error = {
    PoNumber: '',
    endPoNumber: '',
    ccInfo: '',
  };
  loadingFlag: boolean = false;
  maxCredLimit: boolean = false;
  ccPaymentAvailable: boolean = false;
  isCreditCardPayment: boolean = false;
  ccInfo: boolean = false;
  payInfo: any = {};
  @Input() showCreditCard;
  @Input() hidePurchaseOrder;
  @Input() ccPaymentInfo;
  showPayDetailsErr = false;
  hidePurchaseOption: boolean = false;
  @Input() savedPayCards: any;
  snapPayPaymentInfo = new SnapPayPaymentInfo();
  savedCardSelected = false;
  cartData: any;

  ngOnInit(): void {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
      this.ccPaymentAvailable = this.productLine === 'waygate';
    });
    this.cart$.subscribe((res) => {
      this.cartData = res;
      if (res.totalPrice.value > 20000) {
        this.maxCredLimit = true;
      }
    });
    this.buyUserChkService.sendPaymentTYpe('');
    if (this.hidePurchaseOrder === true) {
      this.hidePurchaseOption = true;
      this.onCcPaymentSelect();
    }
    this.chkService.getValidation().subscribe((payment) => {
      if (payment) {
        this.onSubmit();
      }
    });
  }
  selectSavedCard(card: any) {
    this.snapPayPaymentInfo = card;
    this.savedCardSelected = true;
  }
  selectNewCard() {
    this.snapPayPaymentInfo = {};
    this.savedCardSelected = false;
  }
  onCheckCCInfo(e: any, field) {
    if (field === 'ccInfo') {
      this.error.ccInfo = '';
      this.ccInfo = !this.ccInfo;
    }
    this.checkPaymentValid();
  }
  onCcPaymentSelect() {
    if (this.completeOrder) {
      this.isCreditCardPayment = true;
      this.error.PoNumber = '';
      this.ccInfo = false;
      this.buyUserChkService.sendPaymentTYpe('card');
    }
    this.checkPaymentValid();
  }
  onPoSelect() {
    this.isCreditCardPayment = false;
    this.buyUserChkService.sendPaymentTYpe('');
    this.checkPaymentValid();
  }
  getTranslatedText(key) {
    let message;
    this.translate.translate(key).subscribe((res: any) => {
      message = res;
    });
    return message;
  }

  onSubmit() {
    if (this.PoNumber === '' && this.isCreditCardPayment === false) {
      this.error.PoNumber = this.getTranslatedText('errors.poNumber');
      this.error.ccInfo = '';
      // this.scrollToError()
    } else if (
      this.ccInfo === false &&
      this.showCreditCard === true &&
      this.isCreditCardPayment === true
    ) {
      this.error.ccInfo = 'Please accept cc Info';
    }
    if (this.PoNumber && this.isCreditCardPayment === false) {
      this.error.ccInfo = '';
      this.checkoutPaymentModel.purchaseOrderNumber = this.PoNumber.trim();
      this.checkoutPaymentModel.endCustomerOrderNumber = this.endPoNumber
        ? this.endPoNumber.trim()
        : '';
      this.checkoutPaymentModel.costCenterId = '';
      this.checkoutPaymentModel.endUserNumber = '';
      this.checkoutPaymentModel.googleCaptcha = '';
      this.checkoutPaymentModel.paymentType = 'ACCOUNT';
      this.setPaymentData.emit(this.checkoutPaymentModel);
    } else if (
      this.showCreditCard === true &&
      this.isCreditCardPayment === true &&
      this.ccInfo
    ) {
      this.checkoutPaymentModel.purchaseOrderNumber = this.PoNumber
        ? this.PoNumber.trim()
        : 'CREDIT CARD ORDER';
      this.checkoutPaymentModel.endCustomerOrderNumber = this.endPoNumber
        ? this.endPoNumber.trim()
        : '';
      this.checkoutPaymentModel.costCenterId = '';
      this.checkoutPaymentModel.endUserNumber = '';
      this.checkoutPaymentModel.googleCaptcha = '';
      this.checkoutPaymentModel.paymentType = 'card';
      this.snapPayPaymentInfo.isNewCard = true;
      if (this.savedCardSelected === true) {
        this.snapPayPaymentInfo.isNewCard = false;
      }
      this.sendPayInfoData.emit(this.snapPayPaymentInfo);
      this.setPaymentData.emit(this.checkoutPaymentModel);
    }
  }
  scrollToError() {
    window.scrollTo({ top: 350, behavior: 'smooth' });
  }
  onChange(e, field) {
    if (field === 'PoNumber') {
      this.error.PoNumber = '';
      this.PoNumber = e.target.value;
      var pattern = /^[\wÁ-ÿa-zA-Z\d- ]+$/;
      if (e.target.value && !e.target.value.match(pattern)) {
        this.error.PoNumber = this.getTranslatedText('errors.PoNumberInvalid');
      } else this.error.PoNumber = '';
    }

    if (field === 'endPoNumber') {
      this.endPoNumber = e.target.value;
      var pattern = /^[\wÁ-ÿa-zA-Z\d- ]+$/;
      if (e.target.value && !e.target.value.match(pattern)) {
        this.error.endPoNumber = this.getTranslatedText(
          'errors.PoNumberInvalid'
        );
      } else this.error.endPoNumber = '';
    }
    this.checkPaymentValid();
  }
  checkPaymentValid() {
    if (this.isCreditCardPayment === true) {
      if (this.ccInfo == true && this.error.ccInfo === '') {
        this.isPaymentValid.emit(true);
      } else {
        this.isPaymentValid.emit(false);
      }
    } else {
      if (this.PoNumber !== '' && this.error.PoNumber === '') {
        this.isPaymentValid.emit(true);
      } else {
        this.isPaymentValid.emit(false);
      }
    }
  }

  ngOnDestroy() {
    this.chkService.setValidation(false);
  }
}
