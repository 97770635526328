import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { Product, ProductScope, ProductService } from '@spartacus/core';
import {
  CommonConfigurator,
  ConfiguratorRouterExtractorService,
} from '@spartacus/product-configurator/common';
import {
  Configurator,
  ConfiguratorCommonsService,
} from '@spartacus/product-configurator/rulebased';

import { ICON_TYPE } from '@spartacus/storefront';

import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../../../../core/http/api.service';

@Component({
  selector: 'cx-configurator-product-title',
  templateUrl: './configurator-product-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./configurator-product-title.component.scss'],
})
export class ConfiguratorProductTitleComponent {
  technicalErrorFlag: boolean = false;
  resetFlag: boolean = true;
  productCode: string;
  //jsonResponse = [];
  constructor(
    protected configuratorCommonsService: ConfiguratorCommonsService,
    protected configRouterExtractorService: ConfiguratorRouterExtractorService,
    protected productService: ProductService,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}
  product$: Observable<Product> = this.configRouterExtractorService
    .extractRouterData()
    .pipe(
      switchMap((routerData) =>
        this.configuratorCommonsService.getConfiguration(routerData.owner)
      ),
      map((configuration) => {
        switch (configuration.owner.type) {
          case CommonConfigurator.OwnerType.PRODUCT:
          case CommonConfigurator.OwnerType.CART_ENTRY:
            return configuration.productCode;
          case CommonConfigurator.OwnerType.ORDER_ENTRY:
            return configuration.overview.productCode;
        }
      }),
      switchMap((productCode) => this.productService.get(productCode))
    );

  configuration$: Observable<Configurator.Configuration> =
    this.configRouterExtractorService.extractRouterData().pipe(
      switchMap((routerData) => {
        return this.configuratorCommonsService.getConfiguration(
          routerData.owner
        );
      })
    );

  resetData(configId: any) {
    this.resetConfiguration(configId).subscribe(
      (success: any) => {},
      (error: any) => {},
      () => {
        window.location.reload();
      }
    );
  }

  resetConfiguration(configurationId: any) {
    const params = ['ccpconfigurator', 'reset', configurationId];
    const apiUrl = this.apiService.constructUrl(params);
    return this.apiService.postData(apiUrl, {}, { responseType: 'text' });
  }

  downloadCSV(configId: any) {
    let jsonResponse = [];
    this.product$.subscribe((res) => {
      this.productCode = res.code;
    });

    const params = ['ccpconfigurator', configId, 'configurationOverview'];
    const apiUrl = this.apiService.constructUrl(params);
    this.apiService.getData(apiUrl, params).subscribe((overview) => {
      if (overview) {
        (overview as any).groups.forEach((group) => {
          const row = group;
          let groupname = row.groupDescription;
          row.characteristicValues.forEach((config) => {
            const record = {
              'Part Number': this.productCode,
              Group: groupname,
              Configuration: config.characteristic,
              'Configuration Label': config.value,
              'Configuration Option': `="${config.valueId}"`,
            };

            jsonResponse.push(record);
          });
        });
      }
      const data = jsonResponse;
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, 'configurations');
      XLSX.writeFile(workbook, this.productCode + '.csv');
    });
  }
  ngOnInit() {
    setTimeout(() => {
      this.technicalErrorFlag = true;
      this.cdr.detectChanges();
    }, 3000);

    setTimeout(() => {
      this.technicalErrorFlag = false;
      this.cdr.detectChanges();
    }, 20000);
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.resetFlag = false;
      }
    });
  }
}
