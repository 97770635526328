<fieldset>
  <legend style="display: none">{{ attribute.label }}</legend>
  <div id="{{ createAttributeIdForConfigurator(attribute) }}">
    <div
      *ngIf="withQuantityOnAttributeLevel"
      class="cx-attribute-level-quantity-price"
    >
      <cx-configurator-attribute-quantity
        (changeQuantity)="onChangeQuantity($event)"
        [quantityOptions]="
          extractQuantityParameters(attribute.quantity, !attribute.required)
        "
      ></cx-configurator-attribute-quantity>
      <cx-configurator-price
        [formula]="extractPriceFormulaParameters()"
      ></cx-configurator-price>
    </div>
    <ng-container
      *ngIf="attribute.values.length > 10; else lessThanOrEqualTo10"
    >
      <ng-container
        *ngFor="let value of attribute.values | slice : 0 : 10; let i = index"
      >
        <div class="form-check">
          <div class="cx-value-label-pair">
            <input
              id="{{
                createAttributeValueIdForConfigurator(
                  attribute,
                  value.valueCode
                )
              }}"
              type="checkbox"
              class="form-check-input"
              [cxFocus]="{ key: attribute.name + '-' + value.name }"
              [value]="value.valueCode"
              (change)="onSelect()"
              [formControl]="attributeCheckBoxForms[i]"
              name="{{ createAttributeIdForConfigurator(attribute) }}"
              [attr.aria-label]="
                value.valuePrice && value.valuePrice?.value !== 0
                  ? value.valuePriceTotal && value.valuePriceTotal?.value !== 0
                    ? ('configurator.a11y.valueOfAttributeFullWithPrice'
                      | cxTranslate
                        : {
                            value: value.valueDisplay,
                            attribute: attribute.label,
                            price: value.valuePriceTotal.formattedValue,
                          })
                    : ('configurator.a11y.valueOfAttributeFullWithPrice'
                      | cxTranslate
                        : {
                            value: value.valueDisplay,
                            attribute: attribute.label,
                            price: value.valuePrice.formattedValue,
                          })
                  : ('configurator.a11y.valueOfAttributeFull'
                    | cxTranslate
                      : {
                          value: value.valueDisplay,
                          attribute: attribute.label,
                        })
              "
              [attr.aria-describedby]="
                createAttributeUiKey('label', attribute.name)
              "
            />
            <label
              id="{{
                createValueUiKey('label', attribute.name, value.valueCode)
              }}"
              for="{{
                createAttributeValueIdForConfigurator(
                  attribute,
                  value.valueCode
                )
              }}"
              aria-hidden="true"
              class="cx-configurator-attribute-value-label form-check-label"
              ><b>{{ value.valueCode }}</b>
              {{ ' : ' + value.valueDisplay }}</label
            >
          </div>
          <div class="cx-value-price">
            <cx-configurator-price
              [formula]="extractValuePriceFormulaParameters(value)"
            ></cx-configurator-price>
          </div>
        </div>
        <cx-configurator-attribute-quantity
          *ngIf="
            value.selected && withQuantity && !withQuantityOnAttributeLevel
          "
          (changeQuantity)="onChangeValueQuantity($event, value.valueCode, i)"
          [quantityOptions]="
            extractQuantityParameters(value.quantity, allowZeroValueQuantity)
          "
        ></cx-configurator-attribute-quantity>
      </ng-container>
    </ng-container>

    <ng-template #lessThanOrEqualTo10>
      <ng-container *ngFor="let value of attribute.values; let i = index">
        <div class="form-check">
          <div class="cx-value-label-pair">
            <input
              id="{{
                createAttributeValueIdForConfigurator(
                  attribute,
                  value.valueCode
                )
              }}"
              type="checkbox"
              class="form-check-input"
              [cxFocus]="{ key: attribute.name + '-' + value.name }"
              [value]="value.valueCode"
              (change)="onSelect()"
              [formControl]="attributeCheckBoxForms[i]"
              name="{{ createAttributeIdForConfigurator(attribute) }}"
              [attr.aria-label]="
                value.valuePrice && value.valuePrice?.value !== 0
                  ? value.valuePriceTotal && value.valuePriceTotal?.value !== 0
                    ? ('configurator.a11y.valueOfAttributeFullWithPrice'
                      | cxTranslate
                        : {
                            value: value.valueDisplay,
                            attribute: attribute.label,
                            price: value.valuePriceTotal.formattedValue,
                          })
                    : ('configurator.a11y.valueOfAttributeFullWithPrice'
                      | cxTranslate
                        : {
                            value: value.valueDisplay,
                            attribute: attribute.label,
                            price: value.valuePrice.formattedValue,
                          })
                  : ('configurator.a11y.valueOfAttributeFull'
                    | cxTranslate
                      : {
                          value: value.valueDisplay,
                          attribute: attribute.label,
                        })
              "
              [attr.aria-describedby]="
                createAttributeUiKey('label', attribute.name)
              "
            />
            <label
              id="{{
                createValueUiKey('label', attribute.name, value.valueCode)
              }}"
              for="{{
                createAttributeValueIdForConfigurator(
                  attribute,
                  value.valueCode
                )
              }}"
              aria-hidden="true"
              class="cx-configurator-attribute-value-label form-check-label"
              ><b>{{ value.valueCode }}</b>
              {{ ' : ' + value.valueDisplay }}</label
            >
          </div>
          <div class="cx-value-price">
            <cx-configurator-price
              [formula]="extractValuePriceFormulaParameters(value)"
            ></cx-configurator-price>
          </div>
        </div>
        <cx-configurator-attribute-quantity
          *ngIf="
            value.selected && withQuantity && !withQuantityOnAttributeLevel
          "
          (changeQuantity)="onChangeValueQuantity($event, value.valueCode, i)"
          [quantityOptions]="
            extractQuantityParameters(value.quantity, allowZeroValueQuantity)
          "
        ></cx-configurator-attribute-quantity>
      </ng-container>
    </ng-template>
  </div>
</fieldset>
