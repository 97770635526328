import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { AllProductLine } from '../../../shared/enums/availableProductList.enum';
import { Router } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { CustomerAccountService } from '../../../core/customer-account/customer-account.service';
import { take } from 'rxjs/operators';
// import { NewArrivalSlot } from '../../../shared/products-constants';

@Component({
  selector: 'app-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss'],
})
export class QuickLinkComponent {
  menuItems = [
    {
      name: 'Browse Products',
      iconImg: '../../../../assets/img/browseProductsIcon.png',
      link: 'panametrics/categories/ECOM_LVL1_00000002/Panametrics',
    },
    {
      name: 'My Orders',
      iconImg: '../../../../assets/img/myOrdersIcon.png',
      link: '/panametrics/my-orders',
    },
    {
      name: 'My Returns',
      iconImg: '../../../../assets/img/myReturnsIcon.png',
      link: '/rma-tracking',
    },
    { name: 'Create RM', iconImg: `/`, link: '/rma-form' },
    {
      name: 'Calibration Certificate',
      iconImg: '../../../../assets/img/calibrationIcon.png',
      link: '/panametrics/calibration-data',
    },
    {
      name: 'Track Orders',
      iconImg: '../../../../assets/img/trackOrderIcon.png',
      link: '/panametrics/track-order',
    },
    {
      name: 'Customer Support',
      iconImg: '../../../../assets/img/customerSupportIcon.png',
      link: 'panametrics/contactus',
    },
    {
      name: 'Supportive Links',
      iconImg: '../../../../assets/img/supportiveLinksIcon.png',
      link: '/list-of-portals',
    },
  ];

  componentArraylist: any = [];
  baseSiteURL = environment.occBaseUrl;
  productLine: string;
  allProductLine = AllProductLine;
  constructor(
    private router: Router,
    private cmsService: CmsService,
    private custAccService: CustomerAccountService
  ) {}

  ngOnInit(): void {
    this.custAccService
      .getProductLine()
      .pipe(take(1))
      .subscribe((productLine) => {
        this.productLine = productLine;
      });
  }
}
